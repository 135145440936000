import produce from 'immer';

export const initialState = {
  workerList: [],
  assignedWorkers: [],
  details: [],
  filteredDetails: [],
};

export const actions = {
  INIT: 'INIT',
  INIT_WORKER_LIST: 'INIT_WORKER_LIST',
  UPDATE_WORKER_LIST: 'UPDATE_WORKER_LIST',
  TOGGLE_WORKER_SELECTION: 'TOGGLE_WORKER_SELECTION',
  UPDATE_DETAILS: 'UPDATE_DETAILS',
  FILTER: 'FILTER',
};

const iraDetailsReducer = (state, [action, data]) => {
  return produce(state, (draft) => {
    switch (action) {
      case actions.INIT: {
        return {
          workerList: [],
          assignedWorkers: [],
          details: [],
          filteredDetails: [],
        };
      }

      case actions.INIT_WORKER_LIST: {
        const preparedList = data.map((item) =>
          state?.assignedWorkers.findIndex((item2) => item2.id === item.id) >= 0
            ? { ...item, isChecked: true }
            : { ...item, isChecked: false }
        );
        draft.workerList = preparedList;
        break;
      }

      case actions.UPDATE_WORKER_LIST: {
        draft.assignedWorkers = state?.assignedWorkers;
        const preparedList = state?.workerList.map((item) =>
          state?.assignedWorkers.findIndex((item2) => item2.id === item.id) >= 0
            ? { ...item, isChecked: true }
            : { ...item, isChecked: false }
        );
        draft.workerList = preparedList;
        break;
      }

      case actions.TOGGLE_WORKER_SELECTION: {
        const index = state.workerList.findIndex((item) => item.id === data);

        if (index !== -1) {
          draft.workerList[index].isChecked = !state.workerList[index].isChecked;
        }

        break;
      }

      case actions.UPDATE_DETAILS: {
        const preparedData = data?.updates?.length
          ? data.updates.map((item) => {
              return {
                id: item.id,
                skuDetails: item.sku,
                session: data.session,
                worker: item.worker,
                status: getStatus(item),
                issue: item.issue,
                hu: item.hu,
                system: item.system,
                ira: item.ira,
                approved: item.approved,
                action: item.issue !== '' ? item : null,
              };
            })
          : [];
        draft.assignedWorkers = data.assignedWorkers;
        draft.details = preparedData;
        draft.filteredDetails = preparedData.sort(function (row1, row2) {
          return new Date(row2.ira.timestamp) - new Date(row1.ira.timestamp);
        });
        const preparedList = state?.workerList?.map((item) =>
          data?.assignedWorkers.findIndex((item2) => item2.id === item.id) >= 0
            ? { ...item, isChecked: true }
            : { ...item, isChecked: false }
        );
        draft.workerList = preparedList;
        break;
      }

      case actions.FILTER: {
        const { skuFilter, employeeFilter } = data;
        if (!skuFilter && !employeeFilter) {
          draft.filteredDetails = state?.details;
          return;
        }

        let newArr = state?.details
          ?.filter((row) =>
            skuFilter
              ? row.skuDetails.name.toLowerCase().includes(skuFilter.trim().toLowerCase()) ||
                row.skuDetails.code.toLowerCase().includes(skuFilter.trim().toLowerCase())
              : row
          )
          .filter((row) =>
            employeeFilter
              ? row.worker.name.toLowerCase().includes(employeeFilter.trim().toLowerCase()) ||
                row.worker.code.toLowerCase().includes(employeeFilter.trim().toLowerCase())
              : row
          );
        draft.filteredDetails = newArr;
        break;
      }

      default:
        return state;
    }
  });
};

export default iraDetailsReducer;

function getStatus(item) {
  return item.issue !== '' ? (item.approved ? 'Approved' : 'Pending') : 'N/A';
}

//------------------- Session Details Response Schema -------------------------
// {
//   "session": {
//     "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     "code": "string",
//     "kind": "string",
//     "createdAt": "2021-06-25T11:58:31.269Z",
//     "state": "string" :- COMPLETED || PENDING || ONGOING
//   },
//   "assignedWorkers": [
//     {
//       "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//       "code": "string",
//       "name": "string",
//       "phone": "string"
//     }
//   ],
//   "updates": [
//     {
//       "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//       "worker": {
//         "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//         "code": "string",
//         "name": "string",
//         "phone": "string"
//       },
//       "hu": {
//         "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//         "code": "string"
//       },
//       "sku": {
//         "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//         "code": "string",
//         "name": "string",
//         "description": "string"
//       },
//       "system": {
//         "qtyL0": 0,
//         "damagedQtyL0": 0
//       },
//       "ira": {
//         "qtyL0": 0,
//         "damagedQtyL0": 0,
//         "timestamp": "2021-06-25T11:58:31.269Z"
//       },
//       "approved": {
//         "qtyL0": 0,
//         "damagedQtyL0": 0,
//         "timestamp": "2021-06-25T11:58:31.269Z"
//       },
//       "issue": "QTY" || 'SKU' || ''
//     }
//   ]
// }
