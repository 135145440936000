import Pagination from '@material-ui/lab/Pagination';
import React, { useMemo, useState } from 'react';

export function PaginatedList({ numberOfItemsOnPage = 12, dataList, renderItem }) {
  const [activePage, setActivePage] = useState(1);
  const offset = useMemo(() => (activePage - 1) * numberOfItemsOnPage, [
    activePage,
    numberOfItemsOnPage,
  ]);

  const handlePageChange = (e, value) => setActivePage(value);

  return (
    <div>
      {/* Session List Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
        {dataList?.slice(offset, offset + numberOfItemsOnPage).map(renderItem)}
      </div>
      {/* Pagination section */}
      <div className="mt-5 m-auto">
        {dataList?.length / numberOfItemsOnPage > 1 && (
          <Pagination
            onChange={handlePageChange}
            count={Math.ceil(dataList?.length / numberOfItemsOnPage)}
          />
        )}
      </div>
    </div>
  );
}
