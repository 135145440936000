import React from 'react';
import { formatDateTime } from 'utils/utils';
import { CardWrapper, StateLabel } from './SessionTasks';

export function SessionItem({ session }) {
  return (
    <CardWrapper state={session.state}>
      <div className="flex-2 flex-col flex-grow">
        <p className="text-base md:text-base font-bold md:font-bold m-1">{session?.code}</p>
        <div className="flex flex-col">
          <p className="text-xs md:text-xs font-medium md:font-medium m-1">
            {formatDateTime(new Date(session?.createdAt))}
          </p>
          <StateLabel state={session?.state} />
        </div>
      </div>
    </CardWrapper>
  );
}
