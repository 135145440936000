import React, { useState, useRef } from 'react';
import { Box, InputBase, Typography, Avatar } from '@material-ui/core';
import photo from 'images/photo.png';

const styleComps = {
  active: {
    border: 'border-issue border-opacity-60',
    tag: 'block',
  },
  inactive: {
    border: 'border-iraText border-opacity-10',
    tag: 'hidden',
  },
};
export default function IraDetailsMismatch({ issue, sku, onApproveClicked }) {
  const labels = issue === 'SKU' ? styleComps.active : styleComps.inactive;

  const [skuCode, setSkuCode] = useState(sku?.code);
  const [skuName, setSkuName] = useState(sku?.name);

  const [isEditable, setIsEditable] = useState(true);
  const editText = useRef(null);

  const cancelUpdates = () => {
    setIsEditable(true);
    setSkuCode(sku?.code);
    setSkuName(sku?.name);
  };

  const clickedApprove = () => {
    onApproveClicked(skuName, skuCode);
  };

  return (
    <div
      className={`border border-solid p-3 rounded-update flex items-center flex-row justify-between my-5 ${labels.border}`}
    >
      <div>
        <div className={`bg-issue bg-opacity-10 p-2 mb-2 w-max rounded-update ${labels.tag}`}>
          <p className="text-issue font-bold text-xs">Details mismatch</p>
        </div>
        <InputBase
          value={skuCode}
          readOnly={isEditable}
          inputRef={editText}
          inputProps={{ 'aria-label': 'naked' }}
          onChange={(e) => {
            setSkuCode(e.target.value);
          }}
          className={isEditable ? `text-iraText text-opacity-40 font-bold text-md` : null}
        />
        <div className="w-3/4">
          <Typography noWrap className="text-iraText mb-3 font-bold text-lg">
            {sku?.name}
          </Typography>
        </div>
        {!isEditable ? (
          <Box className={`inline-flex ${labels.tag}`}>
            <button
              onClick={() => setIsEditable(true)}
              className="flex-1 bg-blue_button text-white text-sm font-bold rounded-mismatch py-3 px-4 align-middle text-center mr-5 shadow"
            >
              Update
            </button>
            <button
              onClick={cancelUpdates}
              className="flex-1 text-edit text-sm font-bold rounded-mismatch py-3 px-4 align-middle text-center mr-5 shadow"
            >
              Cancel
            </button>
          </Box>
        ) : (
          <Box className={`inline-flex ${labels.tag}`}>
            <button
              onClick={clickedApprove}
              className="flex-1 bg-approve_bg text-white text-sm font-bold rounded-mismatch py-3 px-4 align-middle text-center mr-5 shadow"
            >
              Approve
            </button>
            {/* <button
              onClick={() => {
                setIsEditable(false);
                editText.current.focus();
              }}
              className="flex-1 rounded-mismatch border border-iraText border-opacity-10 text-edit text-sm px-6 py-3 align-middle text-center shadow"
            >
              Edit
            </button> */}
          </Box>
        )}
      </div>
      <div className="bg-blue_button bg-opacity-10">
        <Avatar
          width={80}
          height={80}
          variant="square"
          src={sku?.images ? sku?.images[0] : photo}
          className="rounded"
        />
      </div>
    </div>
  );
}
