import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import LinearProgress from '@material-ui/core/LinearProgress';
import Slide from '@material-ui/core/Slide';
import PersonAddTwoToneIcon from '@material-ui/icons/PersonAddTwoTone';
import {
  useCompleteIRA,
  useIraAssignWorker,
  useIRASessionDetails,
  useUpdateIRA,
  useWMSWorkers,
} from 'apiHooks/wmsHooks';
import React, { useEffect, useReducer, useState } from 'react';
import { Checkbox, Modal } from 'rsuite';
import { useBranchId, useSettings } from 'utils/customHooks';
import { Action, Employee, HandlingUnit, IraSession, Issue, SkuDetails } from './IraCustomCells';
import iraDetailsReducer, { actions, initialState } from './iraDetailsReducer';
import IraDetailsTable from './IraDetailsTable';
import IraIssuesContent, { IraIssuesContentClassical } from './IraIssuesContent';
import StatusFilter from './StatusFilter';
import TableFilters from './tableFilters';

export default function IraDetails(props) {
  const { sessionId, sessionCode, statusOfSession } = props;
  const branchId = useBranchId();
  const { wmsType } = useSettings();
  const isDefaultType = wmsType === 'breakbulk';

  const [state, dispatch] = useReducer(iraDetailsReducer, initialState);
  const [assignWorkerDialogVisibility, setAssignWorkerDialogVisibility] = useState(false);
  const [mismatchDialog, setMismatchDialog] = useState(false);
  const [selectedIraIssue, setSelectedIraIssue] = useState();
  const [confirmDialogVisibility, setConfirmDialogVisibility] = useState(false);

  // Columns for table
  const columns = [
    {
      Header: isDefaultType ? 'HU Code' : 'Bin Code',
      accessor: isDefaultType ? 'hu' : 'bin',
      Cell: ({ cell: { value } }) => <HandlingUnit values={value} />,
    },
    {
      Header: 'SKU Details',
      accessor: 'skuDetails',
      Cell: ({ cell: { value } }) => <SkuDetails values={value} />,
    },
    {
      Header: 'Inventory Count TimeStamp',
      accessor: (d) => d.ira.timestamp,
      Cell: ({ cell: { value } }) => <IraSession values={value} />,
    },
    {
      Header: 'Employee',
      accessor: 'worker',
      Cell: ({ cell: { value } }) => <Employee values={value} />,
    },
    {
      Header: 'Issue',
      accessor: 'issue',
      Cell: ({ cell: { value } }) => <Issue values={value} />,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: StatusFilter,
      filter: 'includes',
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ cell: { value } }) => {
        return (
          <>
            {value?.issue && value?.issue !== '' ? (
              <>
                <Action
                  state={statusOfSession}
                  onClicked={() => {
                    setSelectedIraIssue(value);
                    setMismatchDialog(true);
                  }}
                />
              </>
            ) : value?.session?.state === 'COMPLETED' ? (
              <div>Locked</div>
            ) : (
              <p>N/A</p>
            )}
          </>
        );
      },
    },
  ];

  const { isFetching: isFetchingWorkerList } = useWMSWorkers({
    branchId,
    onSuccess: (data) => {
      dispatch([actions.INIT_WORKER_LIST, data]);
    },
  });

  const [assignWorker, { status }] = useIraAssignWorker({
    sessionId,
    branchId,
    onSuccess: () => {
      setAssignWorkerDialogVisibility(false);
      refetchSessionDetails();
    },
  });

  const {
    refetch: refetchSessionDetails,
    isFetching: isFetchingSessionDetails,
  } = useIRASessionDetails({
    branchId,
    sessionId,
    onSuccess: (data) => {
      dispatch([actions.UPDATE_DETAILS, data]);
      dispatch([actions.UPDATE_WORKER_LIST, data.assignedWorkers]);
    },
  });

  const [updateMismatch, { status: updateMismatchStatus }] = useUpdateIRA({
    branchId,
    onSuccess: () => {
      setMismatchDialog(false);
      refetchSessionDetails();
    },
  });
  const [completeIraSession, { status: completeSessionStatus }] = useCompleteIRA({
    sessionId,
    branchId,
    onSuccess: () => refetchSessionDetails(),
  });

  const [skuFilter, setSkuFilter] = useState('');
  const [employeeFilter, setEmployeeFilter] = useState('');

  useEffect(() => {
    const filterCriteria = { skuFilter, employeeFilter };
    dispatch([actions.FILTER, filterCriteria]);
  }, [skuFilter, employeeFilter]);

  return (
    <div className=" bg-background w-screen h-screen">
      {isFetchingWorkerList || isFetchingSessionDetails || completeSessionStatus === 'loading' ? (
        <LinearProgress
          classes={{
            colorPrimary: 'bg-green-300',
          }}
        />
      ) : null}

      {/* Content Section */}
      <div className="flex flex-col p-5">
        {/* Section 1 containing info about the assigned workers */}
        <AssignedWorkers workerList={state?.assignedWorkers} />

        {/* Section 2 containing table */}
        <div className="flex flex-col rounded md:rounded-lg shadow md:shadow-lg bg-white p-5 mt-5">
          {/* header section containing count of approvals, search, filters etc */}
          <TableFilters
            pending={state?.details?.filter((item) => item.issue !== '' && !item.approved)?.length}
            employeeFilter={employeeFilter}
            setEmployeeFilter={setEmployeeFilter}
            skuFilter={skuFilter}
            setSkuFilter={setSkuFilter}
          />
          {/* table section or content section */}
          <div className="flex flex-col">
            <IraDetailsTable
              columns={columns}
              data={state?.filteredDetails ? state?.filteredDetails : []}
            />
          </div>
        </div>

        {/* Section 3 complete button */}
        <div className="w-screen fixed bottom-3 inset-x-1/2 justify-center items-center">
          <Button
            disabled={statusOfSession === 'COMPLETED' ? true : false}
            variant="contained"
            color="primary"
            onClick={() => setConfirmDialogVisibility(true)}
          >
            {statusOfSession === 'COMPLETED' ? 'Completed' : `Complete Inventory Count for ${sessionCode}`}
          </Button>
        </div>

        {/*FAB Section */}
        <div className="fixed bottom-5 right-5">
          <Fab
            disabled={statusOfSession === 'COMPLETED' ? true : false}
            size="medium"
            color="primary"
            aria-label="assign_worker"
            onClick={() => setAssignWorkerDialogVisibility(true)}
          >
            <PersonAddTwoToneIcon />
          </Fab>
        </div>
      </div>

      {/* Assign Worker dialog */}
      <Modal
        size="sm"
        overflow={true}
        show={assignWorkerDialogVisibility}
        onHide={() => setAssignWorkerDialogVisibility(false)}
      >
        <Modal.Body>
          <AssignWorkerDialogContent
            loading={status === 'loading' ? true : false}
            workerList={state?.workerList}
            onCheckboxClicked={(id) => {
              dispatch([actions.TOGGLE_WORKER_SELECTION, id]);
            }}
            onAssignWorkerConfirmed={() => {
              const ids = [];
              state?.workerList.map((item) => {
                if (item.isChecked) {
                  ids.push(item.id);
                }
              });
              assignWorker({ workerIds: ids });
            }}
            onCancel={() => setAssignWorkerDialogVisibility(false)}
          />
        </Modal.Body>
      </Modal>

      {/* Ira Issue Content dialog */}
      <Modal
        size="sm"
        overflow={true}
        show={mismatchDialog}
        onHide={() => setMismatchDialog(false)}
      >
        <Modal.Body>
          {isDefaultType ? (
            <IraIssuesContent
              data={selectedIraIssue}
              onClose={() => setMismatchDialog(false)}
              status={updateMismatchStatus === 'loading' ? true : false}
              onApproveClicked={(id, damagedQty, quantity) =>
                updateMismatch({ updateId: id, damagedQty: damagedQty, quantity: quantity })
              }
            />
          ) : (
            <IraIssuesContentClassical
              data={[selectedIraIssue, selectedIraIssue]}
              onClose={() => setMismatchDialog(false)}
              status={updateMismatchStatus === 'loading' ? true : false}
              onApproveClicked={(id, damagedQty, quantity) =>
                updateMismatch({ updateId: id, damagedQty: damagedQty, quantity: quantity })
              }
            />
          )}
        </Modal.Body>
      </Modal>

      {/* Confirmation dialog to complete the session */}
      <ConfirmationDialog
        title={'Attempting to complete the Inventory Count session'}
        hide={() => setConfirmDialogVisibility(false)}
        confirmDialogVisibility={confirmDialogVisibility}
        completeSession={completeIraSession}
      />
    </div>
  );
}

export function AssignedWorkers({ workerList }) {
  return (
    <div className="flex flex-col rounded md:rounded-lg shadow md:shadow-lg bg-white p-5 mt-5">
      <p className="text-lg md:text-2xl font-semibold md:font-semibold text-primary">
        Assigned workers
      </p>
      <div className="flex flex-wrap space-x-3 my-2">
        {workerList?.length ? (
          workerList?.map((item) => {
            return (
              <Chip
                key={item?.id}
                clickable={false}
                avatar={<Avatar alt={item?.name} src="/static/images/avatar/1.jpg" />}
                label={`${item?.name} (${item?.code})`}
                //onDelete={handleDelete}
              />
            );
          })
        ) : (
          <p>No worker is assigned</p>
        )}
      </div>
    </div>
  );
}

const transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function ConfirmationDialog({ title, confirmDialogVisibility, hide, completeSession }) {
  const complete = () => {
    completeSession();
    hide();
  };

  return (
    <Dialog
      open={confirmDialogVisibility}
      TransitionComponent={transition}
      keepMounted
      onClose={hide}
      aria-labelledby="confirm-dialog-slide-title"
      aria-describedby="confirm-dialog-slide-description"
    >
      <DialogTitle id="confirm-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-slide-description">
          Please make sure you have approved all item. Once the complete is done, you will not be
          able to approve anything.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={hide} color="primary">
          Disagree
        </Button>
        <Button onClick={complete} color="primary">
          Yes, Go ahead
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function AssignWorkerDialogContent({
  loading,
  workerList,
  onCheckboxClicked,
  onAssignWorkerConfirmed,
  onCancel,
  label = 'Inventory Count',
  title = '',
}) {
  return (
    <div className="container">
      {loading ? (
        <LinearProgress
          classes={{
            colorPrimary: 'bg-green-300',
          }}
        />
      ) : null}
      <p className="text-lg md:text-lg font-semibold md:font-semibold text-primary m-5">
        {title || `Select workers to assign for ${label} task`}
      </p>
      <div className="m-5">
        {workerList?.map((item) => (
          <div key={item.id} className="rounded md:rounded-lg shadow md:shadow-lg bg-white p-2 m-1">
            <Checkbox value={item?.id} checked={item?.isChecked} onChange={onCheckboxClicked}>
              <div className="flex flex-row flex-grow">
                <p className="flex-1 text-base md:text-base font-normal md:font-normal text-primary">
                  {item?.name}
                </p>
                <p className="flex-2 text-sm md:text-sm font-normal md:font-normal text-light_blue">
                  {item?.code}
                </p>
              </div>
            </Checkbox>
          </div>
        ))}
      </div>
      <div className="flex justify-center items-center space-x-2">
        <Button variant="contained" onClick={() => onCancel()}>
          Cancel
        </Button>
        {workerList?.find((item) => item.isChecked) ? (
          <Button
            variant="contained"
            color="primary"
            //disabled={workerList?.find((item) => item.isChecked) ? true : false}
            onClick={() => onAssignWorkerConfirmed()}
          >
            Submit
          </Button>
        ) : null}
      </div>
    </div>
  );
}

// function SkuFilter({ column }) {
//   const { filterValue, setFilter } = column;

//   return (
//     <div>
//       <input
//         value={filterValue || ''}
//         onChange={(e) => setFilter(e.target.value)}
//         placeholder="Sku name / Code"
//       />
//     </div>
//   );
// }
