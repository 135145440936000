import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import React, { useState } from 'react';
import { ConfirmationDialog } from 'wms/ira/IraDetails';

export default function SessionTasks({ session, isFetching, forceComplete }) {
  const [confirmDialogVisibility, setConfirmDialogVisibility] = useState(false);
  // session = {
  //   ...session,
  //   tasks: [
  //     { id: 'wer', code: 'werf', kind: 'ewrfr', state: 'PENDING', progress: { done: 4, taget: 5 } },
  //   ],
  // };

  return (
    <div className="container bg-background w-screen h-screen">
      {isFetching === 'loading' ? (
        <LinearProgress
          classes={{
            colorPrimary: 'bg-green-300',
          }}
        />
      ) : null}

      <div className="flex flex-col w-full h-full p-5">
        <div className="flex-1">
          <p className="text-base md:text-2xl antialiased sm:subpixel-antialiased md:antialiased font-semibold md:font-semibold mx-5">
            {session?.code} Tasks Status
          </p>
          <div className="grid grid-cols-1 md:grid-cols-4">
            {!session?.tasks && (
              <div className="mx-5 my-10">
                <p className="text-medium">No data found</p>
              </div>
            )}
            {session?.tasks?.map((task) => (
              <TaskCard key={task?.id} task={task} />
            ))}
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="flex flex-col p-5">
        {/* Section 3 complete button */}
        {forceComplete && (
          <div className="w-screen fixed bottom-3 inset-x-1/2 justify-center items-center">
            <Button
              disabled={session?.state === 'COMPLETED' ? true : false}
              variant="contained"
              color="primary"
              onClick={() => setConfirmDialogVisibility(true)}
            >
              {session?.state === 'COMPLETED' ? 'Completed' : `Complete session`}
            </Button>
          </div>
        )}
        {/* Confirmation dialog to complete the session */}
        <ConfirmationDialog
          title={'Attempting to complete the Receiving session'}
          hide={() => setConfirmDialogVisibility(false)}
          confirmDialogVisibility={confirmDialogVisibility}
          completeSession={forceComplete}
        />
      </div>
    </div>
  );
}

function TaskCard({ task }) {
  const getMetrics = (task) => {
    if (!task.progress) return '';

    return `Done : ${task.progress.done} Pending : ${task.progress.target - task.progress.done}`;
  };

  return (
    <div
      className={`rounded md:rounded-lg shadow md:shadow-lg bg-white border-2 ${
        task.state === 'COMPLETED' ? 'border-success-green' : 'border-background'
      } m-5 p-3`}
    >
      <div className="flex flex-row">
        <div className="flex flex-col flex-grow">
          <p className="text-base md:text-base font-bold md:font-bold">{task.code}</p>
          <div className="flex flex-col">
            <p className="text md:text-xs font-medium md:font-medium">{getMetrics(task)}</p>
            <p className="text md:text-xs font-medium md:font-medium mt-2">
              <StateLabel state={task.state} />
            </p>
          </div>
        </div>
        <div className="text-right">
          <p className="text-sm md:text-sm font-bold md:font-bold">{task.kind}</p>
        </div>
      </div>
    </div>
  );
}

export function CardWrapper({ state, children, className }) {
  return (
    <div
      className={`rounded md:rounded-lg shadow md:shadow-lg bg-white border-2 ${
        state === 'COMPLETED' ? 'border-success-green' : 'border-background'
      } ${className || 'm-5 p-3'}`}
    >
      {children}
    </div>
  );
}
export function StateLabel({ state }) {
  return (
    <span
      className={`${
        state === 'COMPLETED' ? 'bg-success-green' : 'bg-inactive-dark'
      } px-2 py-1 flex self-start rounded-md text-white`}
    >
      {state}
    </span>
  );
}
