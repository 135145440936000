import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { PaginatedList } from 'wms/molecules/PaginatedList';
import { SessionItem } from 'wms/molecules/SessionItem';

export default function BinConsolidation() {
  const data = [
    { code: 'BINCON-81', createdAt: new Date('29 Mar 2022, 1:06 PM'), state: 'PENDING' },
    { code: 'BINCON-80', createdAt: new Date('28 Mar 2022, 3:59 PM'), state: 'PENDING' },
    { code: 'BINCON-79', createdAt: new Date('24 Mar 2022, 1:18 PM'), state: 'COMPLETED' },
  ];

  return (
    <div className="container">
      <div className="flex flex-col w-full h-full">
        {/* Header Section containing Filters */}
        <div className="flex flex-row m-5 justify-center items-center">
          <div className="flex-1">
            <p className="text-base md:text-2xl antialiased sm:subpixel-antialiased md:antialiased font-semibold md:font-semibold mx-5">
              Bin Consolidation
            </p>
          </div>
        </div>
        {/* Session List Section */}
        <PaginatedList dataList={data} renderItem={(item) => <SessionItem session={item} />} />

        {/* Plus icon */}
        <div className="fixed right-5 md:right-5 bottom-5 md:bottom-5">
          <Fab color="primary" aria-label="add">
            <AddIcon />
          </Fab>
        </div>
      </div>
    </div>
  );
}
