import Button from '@material-ui/core/Button';
import React from 'react';
import { formatDateTime } from 'utils/utils';

const headText = 'font-bold text-sm text-iraText';
const tagText = 'text-iraText text-opacity-40 text-sm';

const HandlingUnit = ({ values }) => {
  const { id, code } = values;
  return (
    <div key={id}>
      <div className={headText}>{code}</div>
    </div>
  );
};

const SkuDetails = ({ values }) => {
  const { id, name, code } = values;
  return (
    <div key={id}>
      <div className={`${headText} line-clamp-2`}>{name}</div>
      <div className={tagText}>{code}</div>
    </div>
  );
};

const IraSession = ({ values }) => {
  const timestamp = values;
  return <div className={tagText}>{formatDateTime(new Date(timestamp))}</div>;
};

const Employee = ({ values }) => {
  const id = values ? values.id : '';
  const code = values ? values.code : '';
  const name = values ? values.name : '';
  return values ? (
    <div key={id}>
      <div className={headText}>{code}</div>
      <div className={tagText}>{name}</div>
    </div>
  ) : (
    <span>N/A</span>
  );
};

const Issue = ({ values }) => {
  return (
    <div
      className={
        values !== ''
          ? `flex flex-row w-max bg-issue p-2 bg-opacity-10 rounded-update items-center`
          : `flex flex-row p-2 items-center`
      }
    >
      <p className={`${values !== '' ? 'text-issue' : 'text-iraText'} font-bold text-xs`}>
        {getIssueName(values)}
      </p>
    </div>
  );
};

const Action = ({ onClicked, state }) => {
  return (
    <div>
      <div className={headText}>
        <Button
          disabled={state === 'COMPLETED' ? true : false}
          onClick={() => onClicked()}
          variant="contained"
          className="bg-blue_button text-white rounded-update text-xs"
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export { HandlingUnit, IraSession, SkuDetails, Employee, Issue, Action };

function getIssueName(val) {
  if (val !== '') {
    return val === 'QTY' ? 'Quantity Mismatch' : 'Details Mismatch';
  }

  return 'N/A';
}
