import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { useBlockLayout, useFilters, useSortBy, useTable } from 'react-table';
import { FixedSizeList } from 'react-window';

export default function IraDetailsTable({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useBlockLayout,
    useFilters,
    useSortBy
  );

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <TableRow
          {...row.getRowProps({
            style,
          })}
          className="flex w-full justify-start"
        >
          {row.cells.map((cell) => {
            return (
              <TableCell
                {...cell.getCellProps()}
                className={`border-b-0 ${tableContent[cell.column.id]?.width}`}
              >
                {cell.render('Cell')}
              </TableCell>
            );
          })}
        </TableRow>
      );
    },
    [prepareRow, rows]
  );

  return (
    <>
      <MaUTable className="m-10 ml-0" {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow
              className="flex items-center bg-iraTable bg-opacity-10 rounded-mismatch w-full"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <TableCell
                  className={`text-left py-2 text-sm text-iraText border-b-0 ${
                    tableContent[column.id]?.width
                  }`}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                  <div>{column.Filter ? column.render('Filter') : null}</div>
                  {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          <FixedSizeList
            height={200}
            itemCount={rows.length}
            itemSize={75}
            // className="table-row-group"
          >
            {RenderRow}
          </FixedSizeList>
        </TableBody>
      </MaUTable>
    </>
  );
}

const tableContent = {
  hu: {
    width: 'w-2/12',
  },
  skuDetails: {
    width: 'w-3/12',
  },
  ira: {
    width: 'w-3/12',
  },
  worker: {
    width: 'w-2/12',
  },
  issue: {
    width: 'w-2/12',
  },
  status: {
    width: 'w-2/12',
  },
  action: {
    width: 'w-2/12',
  },
};
