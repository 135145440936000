import { InputBase, Typography } from '@material-ui/core';
import React, { useRef, useState } from 'react';

const styleComps = {
  active: {
    border: 'border-issue border-opacity-60',
    tag: 'block',
    qtyText: 'text-issue',
  },
  inactive: {
    border: 'border-iraText border-opacity-10',
    tag: 'hidden',
    qtyText: 'text-iraText',
  },
};

export default function IraQuantityMismatch({
  issue,
  ira,
  system,
  onApproveClicked,
  label,
  hideSystem,
  hideDamaged,
}) {
  const [iraVal, setIraVal] = useState(ira?.qtyL0);
  const [damagedVal, setDamagedVal] = useState(ira?.damagedQtyL0);
  const [isEditable, setIsEditable] = useState(true);

  let labels = issue === 'QTY' ? styleComps.active : styleComps.inactive;

  let editText = useRef(null);

  const editMismatch = () => {
    setIsEditable(false);
    editText.current.focus();
  };

  const cancelUpdates = () => {
    setIraVal(ira?.qtyL0);
    setDamagedVal(ira?.damagedQtyL0);
    setIsEditable(true);
  };

  const clickedApprove = () => {
    onApproveClicked(damagedVal, iraVal);
  };

  return (
    <div className={`border border-solid rounded-update p-3 ${labels.border}`}>
      <div className={`bg-issue bg-opacity-10 py-1 px-2 w-max rounded-update my-2 ${labels.tag}`}>
        <p className="text-issue font-bold text-xs ">Quantity mismatch</p>
      </div>
      <Typography className="text-qty text-iraText font-bold">Quantity</Typography>
      <div className="flex space-x-3 my-3">
        <div className="bg-iraTable bg-opacity-10 pt-3 pb-2 px-2 rounded-update flex-1 w-min">
          <Typography className="mb-6 font-bold text-sm">{`${label} quantity`}</Typography>
          <InputBase
            value={iraVal}
            readOnly={isEditable}
            inputRef={editText}
            type="number"
            inputProps={{ 'aria-label': 'naked' }}
            onChange={(e) => {
              setIraVal(e.target.value);
            }}
            className={isEditable && `${labels.qtyText} text-lg font-bold`}
          />
        </div>
        {!hideDamaged && (
          <div
            className={`bg-iraTable bg-opacity-10 pt-3 pb-2 px-2 rounded-update flex-1 w-min ${labels.tag}`}
          >
            <Typography className="mb-6 font-bold text-sm">Damaged</Typography>
            <InputBase
              value={damagedVal}
              readOnly={isEditable}
              type="number"
              inputProps={{ 'aria-label': 'naked' }}
              onChange={(e) => {
                setDamagedVal(e.target.value);
              }}
              className={isEditable && `${labels.qtyText} text-lg font-bold`}
            />
          </div>
        )}
        {!hideSystem && (
          <div className="bg-iraTable bg-opacity-10 pt-3 pb-2 px-2 rounded-update flex-1 w-min">
            <Typography className="mb-6 font-bold text-sm">System quantity</Typography>
            <Typography className="text-iraText font-bold text-lg">{system?.qtyL0}</Typography>
          </div>
        )}
      </div>
      {!isEditable ? (
        <div className={`inline-flex ${labels.tag}`}>
          <button
            onClick={() => setIsEditable(true)}
            className="flex-1 bg-blue_button text-white text-sm font-bold rounded-mismatch py-3 px-4 align-middle text-center mr-5 shadow"
          >
            Update
          </button>
          <button
            onClick={cancelUpdates}
            className="flex-1 text-edit text-sm font-bold rounded-mismatch py-3 px-4 align-middle text-center mr-5 shadow"
          >
            Cancel
          </button>
        </div>
      ) : (
        <div className={`inline-flex ${labels.tag}`}>
          <button
            onClick={clickedApprove}
            className="flex-1 bg-approve_bg text-white text-sm font-bold rounded-mismatch py-3 px-4 align-middle text-center mr-5 shadow"
          >
            Approve
          </button>
          <button
            onClick={editMismatch}
            className="flex-1 rounded-mismatch border border-iraText border-opacity-10 text-edit text-sm px-6 py-3 align-middle text-center shadow"
          >
            Edit
          </button>
        </div>
      )}
    </div>
  );
}
