import IconButton from '@material-ui/core/IconButton';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import React from 'react';

export default function TableFilters({
  pending,
  skuFilter,
  setSkuFilter,
  employeeFilter,
  setEmployeeFilter,
}) {
  const restrictSpace = (e, filter) => {
    const k = e ? e.which : window.e.keyCode;
    if (k == 32) {
      filter === 'skuFilter'
        ? !skuFilter.trim()
          ? setSkuFilter('')
          : setSkuFilter(e.target.value)
        : filter === 'employeeFilter'
        ? !employeeFilter.trim()
          ? setEmployeeFilter('')
          : setEmployeeFilter(e.target.value)
        : null;
    }
  };
  return (
    <div className="flex flex-row justify-between items-center">
      <p className="text-lg md:text-lg font-semibold md:font-semibold text-primary">
        {`${pending} pending approvals`}
      </p>
      <div className="flex flex-row space-x-3">
        <input
          className="w-52 h-9 rounded-sm md:rounded-sm shadow md:shadow-lg px-2 my-2 bg-iraTable bg-opacity-20"
          type="text"
          placeholder="Search by SKU Code/ Name"
          value={skuFilter ? skuFilter : skuFilter.trim()}
          onKeyPress={(e) => restrictSpace(e, 'skuFilter')}
          onChange={(e) => setSkuFilter(e.target.value)}
        />
        <input
          className="w-52 h-9 rounded-sm md:rounded-sm shadow md:shadow-lg px-2 my-2 bg-iraTable bg-opacity-20"
          type="text"
          placeholder="Employee code / name"
          value={employeeFilter}
          onKeyPress={(e) => restrictSpace(e, 'employeeFilter')}
          onChange={(e) => setEmployeeFilter(e.target.value)}
        />
        <IconButton
          color="primary"
          aria-label="reset filters"
          onClick={() => {
            setEmployeeFilter('');
            setSkuFilter('');
          }}
        >
          <RotateLeftIcon />
        </IconButton>
      </div>
    </div>
  );
}
