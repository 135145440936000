import { Box } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import IraDetailsMismatch from './IraDetailsMismatch';
import IraQuantityMismatch from './IraQuantityMismatch';

export default function IraIssuesContent({
  data,
  onClose,
  status,
  onApproveClicked,
  label = 'Inventory Count',
  hideSystem = false,
  hideDamaged = false,
}) {
  return (
    <Box className="px-5">
      {status ? (
        <LinearProgress
          classes={{
            colorPrimary: 'bg-green-300',
          }}
        />
      ) : null}
      <Box className="border border-iraText w-max border-solid rounded-update mt-5 p-3 items-start">
        <CloseIcon fontSize="small" className="cursor-pointer" onClick={onClose} />
      </Box>
      <IraDetailsMismatch
        issue={data?.issue}
        sku={data?.sku}
        onApproveClicked={(skuName, skuCode) =>
          onApproveClicked(data?.id, null, null, skuName, skuCode)
        }
      />
      <IraQuantityMismatch
        issue={data?.issue}
        ira={data?.approved || data?.ira}
        label={label}
        hideSystem={hideSystem}
        hideDamaged={hideDamaged}
        system={data?.system}
        onApproveClicked={(damagedVal, iraVal) =>
          onApproveClicked(data?.id, damagedVal, iraVal, null, null)
        }
      />
    </Box>
  );
}

export function IraIssuesContentClassical({
  data,
  onClose,
  status,
  onApproveClicked,
  label = 'Inventory Count',
  hideSystem = false,
  hideDamaged = false,
}) {
  return (
    <Box className="px-5">
      {status ? (
        <LinearProgress
          classes={{
            colorPrimary: 'bg-green-300',
          }}
        />
      ) : null}
      <Box className="flex flex-row items-center justify-between">
        <Box>
          <p className="font-bold text-iraText text-base">Bin code : {'B01'}</p>
          <Box>
            <p>{'Sku Name'}</p>
            <p>{'Sku code'}</p>
          </Box>
        </Box>
        <Box className="border border-iraText w-max border-solid rounded-update mt-5 p-3 items-start">
          <CloseIcon fontSize="small" className="cursor-pointer" onClick={onClose} />
        </Box>
      </Box>

      {data?.map((x, idx) => (
        <Box key={x?.id + idx} className="my-5">
          <Box className="my-2 border border-solid rounded-update p-3  ">
            <Box className="flex flex-row justify-between">
              <p className="text-md font-semibold">Batch : {'B1'}</p>
              <p className="text-md font-semibold">MRP : {400}</p>
              <p className="text-md font-semibold">UOM : {'Case'}</p>
            </Box>
            <p className="text-md font-semibold">Inventory Owner: {'name'}</p>
          </Box>
          <IraQuantityMismatch
            issue={x?.issue}
            ira={x?.approved || x?.ira}
            label={label}
            hideSystem={hideSystem}
            hideDamaged={hideDamaged}
            system={x?.system}
            onApproveClicked={(damagedVal, iraVal) =>
              onApproveClicked(x?.id, damagedVal, iraVal, null, null)
            }
          />
        </Box>
      ))}
    </Box>
  );
}
