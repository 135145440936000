import { Router } from '@reach/router';
import Layout from 'components/Layout';
import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import BinConsolidation from 'wms/ira/BinConsolidation';

export default function Inventory(props) {
  return (
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Layout {...props}>
        <Router>
          <BinConsolidation path="/wms/inventory/bincon" />
        </Router>
      </Layout>
    </ReactQueryConfigProvider>
  );
}
